import { reactive, ref } from 'vue';
import { GetAll, GetByGuid } from '@/services/asistenteServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useAsistente = () => {
    var asistente = reactive({
        id: null,
        nombre: null,
        identificacion: null,
        descripcion: null
    });

    var isLoading = ref(false);
    var asistentes = ref([]);
    var filter = reactive({
        isOpen: false,
        nombre: null,
        identificacion: null,
        descripcion: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var pagination = ref({});

    const getAsistentes = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                asistentes.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de asistente',
                body: 'No se pudo consultar el listado de asistentes, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentAsistente = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                asistente.id = data.id;
                asistente.nombre = data.nombre;
                asistente.identificacion = data.identificacion;
                asistente.descripcion = data.descripcion;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de asistente',
                body: 'No se pudo consultar el asistente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getAsistentes();
        isLoading.value = false;
    }
    
    return {
        filter,
        asistente,
        asistentes,
        pagination,
        isLoading,
        getAsistentes,
        getCurrentAsistente,
        onPaginationHandle,
        formatDate
    }
}