import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { createPinia } from "pinia";
import router from './router'
import Toast from 'vue-toastification'
import vSelect from "vue-select";
import './assets/css/animate.css';
import './assets/css/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-select/dist/vue-select.css';
import 'bootstrap';
import 'vue-toastification/dist/index.css';
import setupInterceptors from './services/interceptors/setupInterceptors';

const app = createApp(App);

app.use(createPinia());
app.use(Toast, {});
app.use(router);
app.component("v-select", vSelect);

setupInterceptors();

app.mount('#app');
