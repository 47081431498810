import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { Create, GetAll, Delete, Update, GetByGuid } from '@/services/clienteServices';
import { Create as CreateContacto, Delete as DeleteContacto } from '@/services/clienteContactoServices'
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useCliente = () => {
    const router = useRouter();

    const clienteInitialState = {
        id: null,
        codigo: null,
        idTipoRnc: null,
        rnc: null,
        nombre: null,
        razon: null,
        direccion: null,
        email: null,
        idMetodoPago: null,
        idTipoComprobante: null,
        photo: null,
        contactos: []
    };

    var cliente = ref({ ...clienteInitialState });

    const contactoInitialState = {
        id: null,
        idTipoContacto: null,
        tipoContacto: null,
        valor: null,
        representante: null,
        extension: null
    };

    var contacto = reactive({ ...contactoInitialState });


    var isLoading = ref(false);
    var isContactCreateLoading = ref(false);
    var isDeleteLoading = ref(false);
    var deletionDialog = ref({});
    var clientes = ref([]);
    var filter = reactive({
        isOpen: false,
        codigo: null,
        idTipoRnc: null,
        rnc: null,
        nombre: null,
        razon: null,
        email: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        description: null
    });

    var pagination = ref({});

    const rules = {
        idTipoRnc: { required },
        rnc: { required },
        nombre: { required },
        razon: { required },
        direccion: { required },
        email: { required },
        idMetodoPago: { required },
        idTipoComprobante: { required },
    }

    const contactoRules = {
        tipoContacto: { 
            id: {
                required 
            }
        },
        valor: { required },
        representante: { required },
        extension: { required },
    }

    const validate$ = useVuelidate(rules, cliente);
    const vContact$ = useVuelidate(contactoRules, contacto);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            
            var clientePayLoad = {
                ...cliente.value,
                contactos: cliente.value.contactos.map((cc) => {
                    return {
                        idTipoContacto: cc.idTipoContacto,
                        valor: cc.valor,
                        representante: cc.representante,
                        extension: cc.extension
                    }
                })
            }
            
            const response = await Create(clientePayLoad);
    
            if(response.status == httpStatusCode.OK){
                Object.assign(cliente, clienteInitialState);
                cliente.value.contactos = [];

                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                validate$.value.$reset();
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Creación de cliente',
                body: 'No se pudo crear el cliente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getClientes = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                clientes.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de clientes',
                body: 'No se pudo consultar el listado de cliente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentCliente = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                cliente.value = data;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de cliente',
                body: 'No se pudo consultar el cliente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }
            
            isLoading.value = true;
            const response = await Update({
                ...cliente.value,
                contactos: []
            });
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Actualización de cliente',
                body: 'No se pudo actualizar el cliente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getClientes();
        isLoading.value = false;
    }

    const addContactHandle = async () => {
        try {
            const isValid = await vContact$.value.$validate();
            if(!isValid){
                return;
            }

            isContactCreateLoading.value = true;

            if(cliente.value.id){
                const response = await CreateContacto({
                    idCliente: cliente.value.id,
                    idTipoContacto: contacto.tipoContacto.id,
                    valor: contacto.valor,
                    representante: contacto.representante,
                    extension: contacto.extension
                });
        
                if(response.status == httpStatusCode.OK){
                    contacto.id = response.data.value.id;

                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    isContactCreateLoading.value = false;

                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                     
                    return;
                }
            }

            cliente.value.contactos.push({
                ...contacto,
                idTipoContacto: contacto.tipoContacto?.id
            })

            Object.assign(contacto, contactoInitialState);
            vContact$.value.$reset();

            isContactCreateLoading.value = false;
        } catch (error) {
            isContactCreateLoading.value = false;

            toast({
                variant: variant.error,
                title: 'Creación de contacto',
                body: 'No se pudo crear el contacto, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);  
        }
    }

    const onContactoDeleteHandle = async (contact, index) => {
        if(contact.id){
            const response = await DeleteContacto(contact.id);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
                 
                return;
            }
        }        

        cliente.value.contactos.splice(index, 1);
    };

    const onDeleteHandle = (cliente) => {
        deletion.id = cliente.id;
        deletion.description = `Estás seguro de quieres eliminar (#${cliente.id} ${cliente.nombre})?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                router.push({ name: 'ListCliente' });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Manager',
                body: 'Failed to delete manager, please contact support center'
            });
    
            throw new Error(error);   
        }
    }

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        vContact$,
        cliente,
        contacto,
        isLoading,
        isDeleteLoading,
        isContactCreateLoading,
        clientes,
        filter,
        deletion,
        deletionDialog,
        pagination,
        onCreateHandle,
        getClientes,
        getCurrentCliente,
        openFilterHandle,
        onUpdateHandle,
        onPaginationHandle,
        addContactHandle,
        onDeleteHandle,
        onContactoDeleteHandle,
        onDelete,
        formatDate
    }
}