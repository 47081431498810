
import Base from '@/components/MainWrapper.vue'

import ListPaciente from '@/views/pacientes/ListPaciente.vue';
import CreatePaciente from '@/views/pacientes/CreatePaciente.vue';
import UpdatePaciente from '@/views/pacientes/UpdatePaciente.vue';

const pacientes = [{
    path: '/pacientes',
    component: Base,
    children: 
    [{
        path: '',
        name: 'ListPaciente',
        component: ListPaciente,
        meta:{
            title: 'Pacientes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'create',
        name: 'CreatePaciente',
        component: CreatePaciente,
        meta:{
            title: 'Pacientes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'update/:guid',
        name: 'UpdatePaciente',
        component: UpdatePaciente,
        meta:{
            title: 'Pacientes',
            requiredAuth: true,
            roles: []
        }
    }]
}];

export default pacientes;