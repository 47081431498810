<template>
    <div class="input-group-sm mb-1 inline-block w-100">
        <label :for="id" class="form-label input-label" v-if="label">{{ label }}</label>
        <v-select
            :options="data"
            :placeholder="currentPlaceholder"
            v-model="value"
            :getOptionLabel="setLabel"
            :reduce="setCode"
            :loading="isLoading"
            :disabled="disabled"
            v-bind="$attrs"
            :class="{'is-invalid': hasError}"
            >
            <template #option="option">
                <component :is="template" :option="option" v-if="template" />
            </template>
        </v-select>

        <!-- <select 
                class="form-select" 
                aria-label="Default select example" 
                :id="id" 
                v-model="value" 
                v-bind="$attrs"
                :class="{'is-invalid': hasError}">
            <option :value="defaultValue" selected disabled>Seleccione una opción</option>
            <option :value="properties.value == undefined ? item : item[properties.value]" v-for="item in data" :key="item[properties.key]">
                <span v-if="(typeof properties.name) === 'object'">
                    <span v-for="n in properties.name" :key="n">
                        {{ getItemValue(item, n) }} {{ properties.delimiter }}
                    </span>
                </span>
                <span v-else>
                    {{ item[properties.name] }}
                </span>
            </option>
        </select> -->
        <!-- <p class="invalid-feedback" v-for="error in errors" :key="error.$uid">
            {{ error.$message }}
        </p> -->
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update:modelValue', 'change']);

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    label: {
        required: true,
        type: String
    },
    data: {
        type: Array,
        default: () => []
    },
    setLabel: {
        type: Function,
    },
    setCode: {
        type: Object,
        default: () => {},
    },
    template: null,
    placeholder: {
        type: String,
        default: () => "Select an option",
    },
    disabled: {
        type: Boolean,
        default: () => false,
    },
    errors: {
        type: Array,
        default: () => []
    },
    modelValue: null,
});

const hasError = computed(() => props.errors.length > 0);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
    emit('change', value);
  }
});
const currentPlaceholder = computed(() => {
  return hasError.value && props.errors[0].$message
    ? props.errors[0].$message
    : props.placeholder;
});
</script>

<style>
.input-label{
    font-size: 0.8em;
    font-weight: bold;
    margin: 0 !important;
    padding: 0 !important;
}

.invalid-feedback{
    font-size: 0.8em;
}

.vs__dropdown-toggle{
    font-size: 0.9em;
}

.vs__search::placeholder{
    font-size: 0.9em;
}

.is-invalid
.vs__dropdown-toggle{
    border-color: #dc3545 !important;
}

.is-invalid 
.vs__search::placeholder{
    color: #dc3545 !important;
    opacity: 1;
    font-size: 0.9em;
}

</style>