<template>
    <default-layout>
        <section>
            <div>
                <control-section-title 
                    title="Pacientes"
                    description="Listado de pacientes creados"
                    icon="fas fa-user-injured">
                    <template v-slot:control>
                        <custom-button 
                            type="button"
                            text="Filtros"
                            class="btn btn-primary"
                            @click="openFilterHandle()"
                            icon="fas fa-filter fa-sm"
                        />
                    </template>        
                </control-section-title>
            </div>
            <div>
                <paginate-table
                    :pagination="pagination"
                    :headers="['#', 
                            'Nombre', 
                            'Identificación', 
                            'Sexo',
                            'Teléfono',
                            'Nss',
                            'Fecha Creación',
                            '']"
                    :is-loading="isLoading"
                    :is-empty="pacientes.length"
                    empty-message="No hay pacientes para mostrar"
                    @pageChange="onPaginationHandle($event)">
                    
                    <template v-slot:tr>
                        <tr v-for="(paciente, index) in pacientes" :key="paciente.id">
                            <th scope="row">{{ paciente.id }}</th>
                            <td>{{ paciente.nombre }}</td>
                            <td>{{ paciente.identificacion }}</td>
                            <td>{{ paciente.sexo }}</td>
                            <td>{{ paciente.telefono }}</td>
                            <td>{{ paciente.nss }}</td>
                            <td>{{ formatDate(paciente.createdDate) }}</td>
                            <td>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Acción
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link 
                                                    :to="{name: 'UpdatePaciente', params: { guid: paciente.guid}}" 
                                                    class="dropdown-item">
                                                    Ver/Actualizar
                                            </router-link>
                                        </li>
                                        <li>
                                            <button 
                                                    @click="onDeleteHandle(paciente, index)" 
                                                    type="button" 
                                                    class="dropdown-item">
                                                    Eliminar
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </paginate-table>
            </div>

            <alert-dialog 
                    ref="deletionDialog"
                    title="Eliminación de paciente"
                    :description="deletion.description"
                    accept-text="Si"
                    close-text="No"
                    :is-loading="isDeleteLoading"
                    @accept="onDelete()" 
            />
        </section>

        <custom-float-button 
            :options="[{
                route: 'ListPaciente',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreatePaciente',
                icon: 'fas fa-plus'
            }]"
        />

        <side-nav 
                title="Filters" 
                :is-open="filter.isOpen" 
                @cancel="openFilterHandle">
            
                <form @submit.stop.prevent="getPacientes" class="row">
                    <div class="col-12">
                        <custom-input
                            id="identificacion"
                            placeholder="Identificación"
                            label="Identificación"
                            type="text"
                            v-model="filter.identificacion"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="nombre"
                            placeholder="Nombre"
                            label="Nombre"
                            type="text"
                            v-model="filter.nombre"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="fechaDesde"
                            placeholder="Fecha Desde"
                            label="Fecha Desde"
                            type="date"
                            v-model="filter.dateFrom"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="fechaHasta"
                            placeholder="Fecha Hasta"
                            label="Fecha Hasta"
                            type="date"
                            v-model="filter.dateTo"
                        />
                    </div>

                    <div class="col-12">
                        <custom-button 
                            type="submit"
                            text="Filtrar"
                            class="btn btn-primary float-end"
                            :is-loading="isLoading"
                        />
                    </div>
                </form>
        </side-nav>
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import AlertDialog from '@/components/modal/AlertDialog.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import SideNav from '@/components/SideNav.vue'
import PaginateTable from '@/components/table/PaginateTable.vue'
import { usePaciente } from '@/composables/usePaciente'

const { 
    pacientes,
    deletion,
    filter,
    deletionDialog,
    isDeleteLoading,
    isLoading,
    pagination,
    getPacientes,
    onDeleteHandle,
    onDelete,
    formatDate,
    openFilterHandle,
    onPaginationHandle
} = usePaciente();


onMounted(async () => {
    await getPacientes();
});
</script>