<template>
    <default-layout>
        <form @submit.stop.prevent="onUpdateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de cliente"
                    description="Complete el formulario y cree un nuevo cliente"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :text="editingReadOnly ? 'Editar': 'Cancelar'"
                            class="btn btn-warning text-white mx-2"
                            @click="() => editingReadOnly = !editingReadOnly"
                            type="button"
                        />

                        <custom-button 
                            text="Eliminar"
                            class="btn btn-danger text-white mx-2"
                            @click="onDeleteHandle(cliente)"
                            type="button"
                            :disabled="editingReadOnly"
                        />

                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                            :disabled="editingReadOnly"
                        />
                    </template>        
                </control-section-title>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-select 
                    id="tipoIdentificacion"
                    label="Tipo RNC"
                    v-model="cliente.idTipoRnc"
                    :errors="validate$.idTipoRnc.$errors" 
                    :data="rncTipos"
                    :set-code="(model) => model.id"
                    :set-label="(model) => model.nombre"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="identificacion"
                    placeholder="RNC"
                    label="RNC"
                    type="text"
                    v-model="cliente.rnc"
                    :errors="validate$.rnc.$errors"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="nombre"
                    placeholder="Nombre Comercial"
                    label="Nombre Comercial"
                    type="text"
                    v-model="cliente.nombre"
                    :errors="validate$.nombre.$errors"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="razonSocial"
                    placeholder="Razón Social"
                    label="Razón Social"
                    type="text"
                    v-model="cliente.razon"
                    :errors="validate$.razon.$errors"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-input
                    id="email"
                    placeholder="Email"
                    label="Email"
                    type="email"
                    v-model="cliente.email"
                    :errors="validate$.email.$errors"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-input
                    id="direccion"
                    placeholder="Dirección"
                    label="Dirección"
                    type="text"
                    v-model="cliente.direccion"
                    :errors="validate$.direccion.$errors"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-md-6 col-xl-6 col-lg-6">
                <section-title 
                        title="Información de contacto"
                        description="Complete el formulario y cree un nuevo cliente"
                        icon="far fa-address-book" 
                />

                <form @submit.stop.prevent="addContactHandle" class="row">
                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-select 
                            id="tipoContacto"
                            label="Tipo Contacto"
                            v-model="contacto.tipoContacto"
                            :errors="vContact$.tipoContacto.$errors" 
                            :data="tipoContactos"
                            :set-code="(model) => model"
                            :set-label="(model) => model.nombre"
                            :disabled="editingReadOnly"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-input
                            id="contacto"
                            :placeholder="contacto.tipoContacto?.nombre ?? 'Contacto'"
                            :label="contacto.tipoContacto?.nombre ?? 'Contacto'"
                            type="text"
                            v-model="contacto.valor"
                            :errors="vContact$.valor.$errors"
                            :disabled="editingReadOnly"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-input
                            id="representante"
                            placeholder="Representante"
                            label="Representante"
                            type="text"
                            v-model="contacto.representante"
                            :errors="vContact$.representante.$errors"
                            :disabled="editingReadOnly"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-input
                            id="extension"
                            placeholder="Extension"
                            label="Extension"
                            type="text"
                            v-model="contacto.extension"
                            :errors="vContact$.extension.$errors" 
                            :disabled="editingReadOnly"
                        />
                    </div>

                    <div v-for="(contacto, index) in cliente.contactos" :key="index" class="inline">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <custom-select 
                                    id="tipoContacto"
                                    v-model="contacto.idTipoContacto"
                                    :data="tipoContactos"
                                    :set-code="(model) => model.id"
                                    :set-label="(model) => model.nombre"
                                    disabled
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <custom-input
                                    id="contacto"
                                    :placeholder="contacto.tipoContacto?.nombre ?? 'Contacto'"
                                    type="text"
                                    v-model="contacto.valor"
                                    :errors="vContact$.valor.$errors"
                                    disabled 
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <custom-input
                                    id="representante"
                                    placeholder="Representante"
                                    type="text"
                                    v-model="contacto.representante"
                                    :errors="vContact$.representante.$errors"
                                    disabled
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <div class="d-flex">
                                    <custom-input
                                        id="extension"
                                        placeholder="Extension"
                                        type="text"
                                        v-model="contacto.extension"
                                        :errors="vContact$.extension.$errors"
                                        disabled
                                    />

                                    <button @click="onContactoDeleteHandle(contacto, index)" class="btn btn-danger btn-actions ms-2" type="button" :disabled="editingReadOnly">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1 d-none">
                        <button type="submit" class="btn btn-primary mt-3 d-none">
                            <i class="fas fa-check"></i>
                        </button>
                    </div>
                </form>
            </div>

            <div class="col-12 col-md-6 col-xl-6 col-lg-6">
                <section-title 
                        title="Información de facturación"
                        description="Complete el formulario y cree un nuevo cliente"
                        icon="far fa-file-alt"/>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-select 
                            id="tipoMetodoPago"
                            label="Metodo de Pago"
                            v-model="cliente.idMetodoPago"
                            :errors="validate$.idMetodoPago.$errors" 
                            :data="metodoPagos"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                            :disabled="editingReadOnly"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-select 
                            id="tipoComprobante"
                            label="Tipo Comprobante"
                            v-model="cliente.idTipoComprobante"
                            :errors="validate$.idTipoComprobante.$errors" 
                            :data="tipoComprobantes"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                            :disabled="editingReadOnly"
                        />
                    </div>
                </div>
            </div>
        </form>
        
        <alert-dialog 
                ref="deletionDialog"
                title="Eliminación de cliente"
                :description="deletion.description"
                accept-text="Si"
                close-text="No"
                :is-loading="isDeleteLoading"
                @accept="onDelete()" 
        />

        <custom-float-button 
            :options="[{
                route: 'ListCliente',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateCliente',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import SectionTitle from '@/components/header/SectionTitle.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import AlertDialog from '@/components/modal/AlertDialog.vue'
import { useCliente } from '@/composables/useCliente'
import { useTipoRnc } from '@/composables/useTipoRnc'
import { useTipoContacto } from '@/composables/useTipoContacto'
import { useMetodoPago } from '@/composables/useMetodoPago'
import { useTipoComprobante } from '@/composables/useTipoComprobante'

const editingReadOnly = ref(true);

const { 
    validate$,
    vContact$,
    contacto,
    cliente,
    isLoading,
    deletion,
    deletionDialog,
    isDeleteLoading,
    onUpdateHandle,
    onContactoDeleteHandle,
    getCurrentCliente,
    addContactHandle,
    onDeleteHandle,
    onDelete,
} = useCliente();

const { rncTipos, getRncTipos } = useTipoRnc();
const { tipoContactos, getTipoContactos } = useTipoContacto();
const { metodoPagos, getMetodoPagos } = useMetodoPago();
const { tipoComprobantes, getTipoComprobantes } = useTipoComprobante();


const route = useRoute();

onMounted(async () => {
    await getCurrentCliente(route.params.guid)
    await getRncTipos();
    await getTipoContactos();
    await getMetodoPagos();
    await getTipoComprobantes();
})

</script>