import { reactive, ref } from 'vue';
import { GetAll, GetByGuid } from '@/services/grupoProcedimientoServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useGrupoProcedimiento = () => {
    var grupoProcedimiento = reactive({
        id: null,
        nombre: null,
        descripcion: null
    });

    var isLoading = ref(false);
    var grupoProcedimientos = ref([]);
    var filter = reactive({
        isOpen: false,
        nombre: null,
        descripcion: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var pagination = ref({});

    const getGrupoProcedimientos = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                grupoProcedimientos.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de grupo de procedimientos',
                body: 'No se pudo consultar el listado de grupo de procedimientos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentGrupoProcedimiento = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                grupoProcedimiento.id = data.id;
                grupoProcedimiento.nombre = data.nombre;
                grupoProcedimiento.descripcion = data.descripcion;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipo de procedimientos',
                body: 'No se pudo consultar el tipo de procedimientos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getGrupoProcedimientos();
        isLoading.value = false;
    }
    
    return {
        filter,
        grupoProcedimiento,
        grupoProcedimientos,
        pagination,
        isLoading,
        getGrupoProcedimientos,
        getCurrentGrupoProcedimiento,
        onPaginationHandle,
        formatDate
    }
}