<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de cliente"
                    description="Complete el formulario y cree un nuevo cliente"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                        />
                    </template>        
                </control-section-title>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-select 
                    id="tipoIdentificacion"
                    label="Tipo RNC"
                    v-model="cliente.idTipoRnc"
                    :errors="validate$.idTipoRnc.$errors" 
                    :data="rncTipos"
                    :set-code="(model) => model.id"
                    :set-label="(model) => model.nombre"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="identificacion"
                    placeholder="RNC"
                    label="RNC"
                    type="text"
                    v-model="cliente.rnc"
                    :errors="validate$.rnc.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="nombre"
                    placeholder="Nombre Comercial"
                    label="Nombre Comercial"
                    type="text"
                    v-model="cliente.nombre"
                    :errors="validate$.nombre.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="razonSocial"
                    placeholder="Razón Social"
                    label="Razón Social"
                    type="text"
                    v-model="cliente.razon"
                    :errors="validate$.razon.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-input
                    id="email"
                    placeholder="Email"
                    label="Email"
                    type="email"
                    v-model="cliente.email"
                    :errors="validate$.email.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-input
                    id="direccion"
                    placeholder="Dirección"
                    label="Dirección"
                    type="text"
                    v-model="cliente.direccion"
                    :errors="validate$.direccion.$errors" 
                />
            </div>

            <div class="col-12 col-md-6 col-xl-6 col-lg-6">
                <section-title 
                        title="Información de contacto"
                        description="Complete el formulario y cree un nuevo cliente"
                        icon="far fa-address-book" 
                />

                <form @submit.stop.prevent="addContactHandle" class="row">
                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-select 
                            id="tipoContacto"
                            label="Tipo Contacto"
                            v-model="contacto.tipoContacto"
                            :errors="vContact$.tipoContacto.$errors" 
                            :data="tipoContactos"
                            :set-code="(model) => model"
                            :set-label="(model) => model.nombre"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-input
                            id="contacto"
                            :placeholder="contacto.tipoContacto?.nombre ?? 'Contacto'"
                            :label="contacto.tipoContacto?.nombre ?? 'Contacto'"
                            type="text"
                            v-model="contacto.valor"
                            :errors="vContact$.valor.$errors" 
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-input
                            id="representante"
                            placeholder="Representante"
                            label="Representante"
                            type="text"
                            v-model="contacto.representante"
                            :errors="vContact$.representante.$errors" 
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-input
                            id="extension"
                            placeholder="Extension"
                            label="Extension"
                            type="text"
                            v-model="contacto.extension"
                            :errors="vContact$.extension.$errors" 
                        />
                    </div>

                    <div v-for="(contacto, index) in cliente.contactos" :key="index" class="inline">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <custom-select 
                                    id="tipoContacto"
                                    v-model="contacto.tipoContacto"
                                    :errors="vContact$.tipoContacto.$errors" 
                                    :data="tipoContactos"
                                    :set-code="(model) => model"
                                    :set-label="(model) => model.nombre"
                                    disabled
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <custom-input
                                    id="contacto"
                                    :placeholder="contacto.tipoContacto?.nombre ?? 'Contacto'"
                                    type="text"
                                    v-model="contacto.valor"
                                    :errors="vContact$.valor.$errors"
                                    disabled 
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <custom-input
                                    id="representante"
                                    placeholder="Representante"
                                    type="text"
                                    v-model="contacto.representante"
                                    :errors="vContact$.representante.$errors"
                                    disabled
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                <div class="d-flex">
                                    <custom-input
                                        id="extension"
                                        placeholder="Extension"
                                        type="text"
                                        v-model="contacto.extension"
                                        :errors="vContact$.extension.$errors"
                                        disabled
                                    />

                                    <button @click="onContactoDeleteHandle(contacto, index)" class="btn btn-danger btn-actions ms-2" type="button">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1 d-none">
                        <button type="submit" class="btn btn-primary mt-3 d-none">
                            <i class="fas fa-check"></i>
                        </button>
                    </div>


                    <!-- <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                        <single-table
                            :pagination="pagination"
                            :headers="['#', 
                                        'Tipo Contacto', 
                                        'Valor', 
                                        'Representante',
                                        'Extension',
                                        '']"
                            :is-loading="false"
                            :is-empty="cliente.contactos.length"
                            empty-message="No hay datos de contactos">
                            
                            <template v-slot:tr>
                                <tr v-for="(contacto, index) in cliente.contactos" :key="index">
                                    <th scope="row">{{ (index + 1) }}</th>
                                    <td>{{ contacto.tipoContacto.nombre }}</td>
                                    <td>{{ contacto.valor }}</td>
                                    <td>{{ contacto.representante }}</td>
                                    <td>{{ contacto.extension }}</td>
                                    <td>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                Acción
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <button 
                                                            @click="onContactoDeleteHandle(contacto, index)" 
                                                            type="button" 
                                                            class="dropdown-item">
                                                            Eliminar
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </single-table>
                    </div> -->
                </form>
            </div>

            <div class="col-12 col-md-6 col-xl-6 col-lg-6">
                <section-title 
                        title="Información de facturación"
                        description="Complete el formulario y cree un nuevo cliente"
                        icon="far fa-file-alt"/>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-select 
                            id="tipoMetodoPago"
                            label="Metodo de Pago"
                            v-model="cliente.idMetodoPago"
                            :errors="validate$.idMetodoPago.$errors" 
                            :data="metodoPagos"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-select 
                            id="tipoComprobante"
                            label="Tipo Comprobante"
                            v-model="cliente.idTipoComprobante"
                            :errors="validate$.idTipoComprobante.$errors" 
                            :data="tipoComprobantes"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                        />
                    </div>
                </div>
            </div>
        </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListCliente',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateCliente',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import SectionTitle from '@/components/header/SectionTitle.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
// import EmptyIndicator from '@/components/table/EmptyIndicator.vue'
// import SingleTable from '@/components/table/SingleTable.vue'
import { useCliente } from '@/composables/useCliente'
import { useTipoRnc } from '@/composables/useTipoRnc'
import { useTipoContacto } from '@/composables/useTipoContacto'
import { useMetodoPago } from '@/composables/useMetodoPago'
import { useTipoComprobante } from '@/composables/useTipoComprobante'


const { 
    validate$,
    vContact$,
    contacto,
    cliente,
    isLoading,
    onCreateHandle,
    addContactHandle,
    onContactoDeleteHandle
} = useCliente();

const { rncTipos, getRncTipos } = useTipoRnc();
const { tipoContactos, getTipoContactos } = useTipoContacto();
const { metodoPagos, getMetodoPagos } = useMetodoPago();
const { tipoComprobantes, getTipoComprobantes } = useTipoComprobante();

onMounted(async () => {
    await getRncTipos();
    await getTipoContactos();
    await getMetodoPagos();
    await getTipoComprobantes();
})

</script>

<style scoped>

</style>