<template>
    <default-layout>
        <section class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de procedimiento/paciente"
                    description="Complete el formulario y cree un nuevo procedimiento"
                    icon="fas fa-user-injured">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                            @click="onCreateHandle"
                        />
                    </template>        
                </control-section-title>
            </div>

            

            <div class="col-7">
                <div class="accordion" id="procedimientoAccordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="infoPaciente">
                            <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#infoCollapsePaciente" aria-expanded="true" aria-controls="infoCollapsePaciente">
                                Información del paciente
                            </button>
                        </h2>
                        <div id="infoCollapsePaciente" class="accordion-collapse collapse show" aria-labelledby="infoPaciente" data-bs-parent="#procedimientoAccordion">
                            <div class="accordion-body">
                                <div class="row">
                                    <div class="col-12">
                                        <control-section-title 
                                            title="Datos de paciente"
                                            description="Complete el formulario y cree un nuevo procedimiento"
                                            icon="fas fa-user-injured" />
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-xl-3 col-lg-3">
                                        <custom-select 
                                            id="cliente"
                                            label="ARS"
                                            v-model="paciente.idCliente"
                                            :errors="validate$.idCliente.$errors" 
                                            :data="clientes"
                                            :properties="{
                                                    key: 'id',
                                                    name: 'nombre',
                                                    value: 'id'
                                            }"
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-6 col-xl-3 col-lg-3">
                                        <custom-select 
                                            id="tipoIdentificacion"
                                            label="Tipo identificación"
                                            v-model="paciente.idTipoIdentificacion"
                                            :errors="validate$.idTipoIdentificacion.$errors" 
                                            :data="tipoIdentificaciones"
                                            :properties="{
                                                    key: 'id',
                                                    name: 'nombre',
                                                    value: 'id'
                                            }"
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                                        <custom-input
                                            id="identificacion"
                                            placeholder="Identificación"
                                            label="Identificación"
                                            type="text"
                                            v-model="paciente.identificacion"
                                            :errors="validate$.identificacion.$errors"
                                            @change="getPacienteByIdentificacion(paciente.identificacion)"
                                            @click="getPacienteByIdentificacion(paciente.identificacion)"
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                                        <custom-input
                                            id="nombre"
                                            placeholder="Nombre"
                                            label="Nombre"
                                            type="text"
                                            v-model="paciente.nombre"
                                            :errors="validate$.nombre.$errors" 
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                        <custom-input
                                            id="fechaNacimiento"
                                            placeholder="Fecha Nacimiento"
                                            label="Fecha Nacimiento"
                                            type="date"
                                            v-model="paciente.fechaNacimiento"
                                            :errors="validate$.fechaNacimiento.$errors" 
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                        <custom-select 
                                            id="sexo"
                                            label="Sexo"
                                            v-model="paciente.sexo"
                                            :errors="validate$.sexo.$errors" 
                                            :data="[{
                                                id: 'F',
                                                name: 'Femenino'
                                            },
                                            {
                                                id: 'M',
                                                name: 'Masculino'
                                            }]"
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                        <custom-input
                                            id="nss"
                                            placeholder="NSS"
                                            label="NSS"
                                            type="text"
                                            v-model="paciente.nss"
                                            :errors="validate$.nss.$errors" 
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                        <custom-input
                                            id="email"
                                            placeholder="Email"
                                            label="Email"
                                            type="email"
                                            v-model="paciente.email"
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                                        <custom-input
                                            id="telefono"
                                            placeholder="Teléfono"
                                            label="Teléfono"
                                            type="tel"
                                            v-model="paciente.telefono"
                                            :errors="validate$.telefono.$errors" 
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12 col-xl-21 col-lg-12">
                                        <custom-input
                                            id="direccion"
                                            placeholder="Dirección"
                                            label="Dirección"
                                            type="text"
                                            v-model="paciente.direccion" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="diagnosticoAccordion">
                            <button class="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#diagnosticoCollapseAccordion" aria-expanded="false" aria-controls="diagnosticoCollapseAccordion">
                                Diagnósticos
                            </button>
                        </h2>
                        <div id="diagnosticoCollapseAccordion" class="accordion-collapse collapse" aria-labelledby="diagnosticoAccordion" data-bs-parent="#procedimientoAccordion">
                            <div class="accordion-body">
                                <div>
                                    <section-title 
                                                title="Diagnósticos"
                                                description="Complete el formulario y agregue un diagnóstico"
                                                icon="fas fa-brain" 
                                    />
                                    
                                    <form @submit.stop.prevent="addDiagnosticoHandle" class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                            <custom-input
                                                    id="diagnostico"
                                                    placeholder="Diagnóstico"
                                                    label="Diagnóstico"
                                                    type="text"
                                                    v-model="diagnostico.descripcion"
                                                    :errors="vDiagnostico$.descripcion.$errors" 
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                            <button type="submit" class="btn btn-primary float-end mb-3">Agregar</button>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                            <single-table
                                                        :headers="['#', 'Diagnóstico', '']"
                                                        :is-loading="false"
                                                        :is-empty="paciente.diagnosticos.length"
                                                        empty-message="No hay datos de Diagnósticos">
                                                        
                                                        <template v-slot:tr>
                                                            <tr v-for="(diag, index) in paciente.diagnosticos" :key="index">
                                                                <th scope="row">{{ (index + 1) }}</th>
                                                                <td>{{ diag.descripcion }}</td>
                                                                <td>
                                                                    <div class="btn-group">
                                                                        <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            Acción
                                                                        </button>
                                                                        <ul class="dropdown-menu">
                                                                            <li>
                                                                                <button 
                                                                                        @click="deleteDiagnosticoHandle(diag, index)" 
                                                                                        type="button" 
                                                                                        class="dropdown-item">
                                                                                        Eliminar
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </template>
                                            </single-table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Procedimientos
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#procedimientoAccordion">
                            <div class="accordion-body">
                                <control-section-title 
                                    title="Procedimientos"
                                    description="Complete el formulario y agregue las procedimientos"
                                    icon="fas fa-user-injured">
                                    <template v-slot:control>
                                        <custom-button 
                                            :is-loading="isLoading"
                                            text="Guardar"
                                            class="btn btn-primary"
                                            @click="onCreateHandle"
                                        />
                                    </template>        
                                </control-section-title>

                                <form @submit.stop.prevent="addProcedimientoHandle" class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6">
                                        <custom-select 
                                            id="medico"
                                            label="Médicos"
                                            v-model="procedimiento.medico"
                                            :errors="vProcedimiento$.medico.$errors" 
                                            :data="medicos"
                                            :properties="{
                                                    key: 'id',
                                                    name: [
                                                        'nombre',
                                                        'apellido',
                                                        'identificacion'
                                                    ]
                                            }"
                                        />
                                    </div>

                                    <div class="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                                        <custom-input
                                            id="autorizacion"
                                            placeholder="No. Autorización"
                                            label="No. Autorización"
                                            type="text"
                                            v-model="procedimiento.noAutorizacion" 
                                        />
                                    </div>

                                    <div class="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                                        <custom-input
                                            id="caso"
                                            placeholder="No. Caso"
                                            label="No. Caso"
                                            type="text"
                                            v-model="procedimiento.noCaso" 
                                        />
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="px-4 py-3 header-line">
                                            <h1 class="fs-6 my-0 fw-bold">Detalle</h1>
                                            <p class="fs-9">Agrege procesos a realizar</p>
                                            <form @submit.stop.prevent="addProcedimientoDetalleHandle">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                                        <custom-select 
                                                            id="grupoProcedimiento"
                                                            label="Tipo Servicio"
                                                            v-model="procedimientoDetalle.idGrupoProcedimiento"
                                                            :errors="vProcedimientoDetalle$.idGrupoProcedimiento.$errors" 
                                                            :data="grupoProcedimientos"
                                                            :properties="{
                                                                    key: 'id',
                                                                    name: 'nombre',
                                                                    value: 'id'
                                                            }"
                                                            @change="getTipoProcedimientoByIdGrupo(procedimientoDetalle.idGrupoProcedimiento)"
                                                        />
                                                    </div>

                                                    
                                                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                                        <custom-select 
                                                            id="tipoServicios"
                                                            label="Servicios"
                                                            v-model="procedimientoDetalle.tipoProcedimiento"
                                                            :errors="vProcedimientoDetalle$.tipoProcedimiento.$errors" 
                                                            :data="tipoProcedimientos"
                                                            :properties="{
                                                                    key: 'id',
                                                                    name: 'nombre'
                                                            }"
                                                            @change="changePrice(procedimientoDetalle.tipoProcedimiento?.precioBase)"
                                                        />
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                                        <custom-input
                                                            id="cantidad"
                                                            placeholder="Cantidad"
                                                            label="Cantidad"
                                                            type="number"
                                                            v-model="procedimientoDetalle.cantidad"
                                                            :errors="vProcedimientoDetalle$.cantidad.$errors" 
                                                        />
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                                        <custom-input
                                                            id="precio"
                                                            placeholder="Precio"
                                                            label="Precio"
                                                            type="number"
                                                            v-model="procedimientoDetalle.precio"
                                                            :errors="vProcedimientoDetalle$.precio.$errors" 
                                                        />
                                                    </div>
                                                    
                                                    <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                                        <custom-input
                                                            id="cobertura"
                                                            placeholder="%"
                                                            label="%"
                                                            type="number"
                                                            v-model="procedimientoDetalle.ptcCobertura" 
                                                        />
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                                        <custom-input
                                                            id="descuento"
                                                            placeholder="Descuento"
                                                            label="Descuento"
                                                            type="number"
                                                            v-model="procedimientoDetalle.descuento"
                                                        />
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                                        <button type="submit" class="btn btn-primary float-end mb-3">Agregar</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                        <single-table
                                                :pagination="pagination"
                                                :headers="['#', 
                                                            'Tipo Proceso', 
                                                            'Cantidad', 
                                                            'Precio',
                                                            'Total',
                                                            'Cobertura',
                                                            'Descuento',
                                                            'Diferencia',
                                                            '']"
                                                :is-loading="false"
                                                :is-empty="procedimiento.detalles.length"
                                                empty-message="No hay datos procedimientos">
                                                
                                                <template v-slot:tr>
                                                    <tr v-for="(proc, index) in procedimiento.detalles" :key="index">
                                                        <th scope="row">{{ (index + 1) }}</th>
                                                        <td>{{ proc.tipoProcedimiento.nombre }}</td>
                                                        <td>{{ proc.cantidad }}</td>
                                                        <td>{{ formatPrice(proc.precio) }}</td>
                                                        <td>{{ formatPrice(proc.total) }}</td>
                                                        <td>{{ formatPrice(proc.cobertura) }}</td>
                                                        <td>{{ formatPrice(proc.descuento) }}</td>
                                                        <td> {{ formatPrice(proc.diferencia) }}</td>
                                                        <td>
                                                            <div class="btn-group">
                                                                <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Acción
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    <li>
                                                                        <button 
                                                                                @click="deleteProcedimientoDetalleHandle(proc, index)" 
                                                                                type="button" 
                                                                                class="dropdown-item">
                                                                                Eliminar
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                        </single-table>
                                    </div>

                                    <div class="col-12">
                                        <button type="submit" class="btn btn-primary float-end mb-3">Crear</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-5">
                <div class="shadow-lg p-5">
                    <!-- TODO: create preview -->
                </div>
            </div>
        </section>
        
        <custom-float-button 
            :options="[{
                route: 'ListPaciente',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreatePaciente',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import SectionTitle from '@/components/header/SectionTitle.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import SingleTable from '@/components/table/SingleTable.vue'
// import CustomLabel from '@/components/label/CustomLabel.vue'
import { usePaciente } from '@/composables/usePaciente'
import { useCliente } from '@/composables/useCliente'
import { useMedico } from '@/composables/useMedico'
import { useTipoIdentificacion } from '@/composables/useTipoIdentificacion'
import { useGrupoProcedimiento } from '@/composables/useGrupoProcedimiento'
import { useTipoProcedimiento } from '@/composables/useTipoProcedimiento'

const { 
    validate$,
    vDiagnostico$,
    vProcedimiento$,
    vProcedimientoDetalle$,
    paciente,
    procedimientoDetalle,
    diagnostico,
    isLoading,
    procedimiento,
    onCreateHandle,
    addDiagnosticoHandle,
    addProcedimientoHandle,
    addProcedimientoDetalleHandle,
    // deleteProcedimientoHandle,
    deleteDiagnosticoHandle,
    deleteProcedimientoDetalleHandle,
    getPacienteByIdentificacion,
    formatPrice
} = usePaciente();

const { clientes, getClientes } = useCliente();
const { tipoIdentificaciones, getTipoIdentificaciones } = useTipoIdentificacion();
const { medicos, getMedicos } = useMedico();
const { tipoProcedimientos, getTipoProcedimientoByIdGrupo } = useTipoProcedimiento();
const { grupoProcedimientos, getGrupoProcedimientos } = useGrupoProcedimiento();

onMounted(async () => {
    await getTipoIdentificaciones();
    await getClientes();
    await getMedicos();
    await getGrupoProcedimientos();
});

const changePrice = (precioBase) => {
    procedimientoDetalle.precio = precioBase;
}

</script>